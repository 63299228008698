import { Routes } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { noAuthGuard } from './auth/guards/no-auth.guard';
import { authGuard } from './auth/guards/auth.guard';
import { GolfDigestComponent } from './golf-digest/golf-digest.component';
import { GolfDigestLayoutComponent } from './layout/golf-digest-layout/golf-digest-layout.component';
import { GolfDigestLoginLayoutComponent } from './layout/golf-digest-login-layout/golf-digest-login-layout.component';
import { VoiceResponseLayoutComponent } from './layout/voice-response-layout/voice-response-layout.component';
import { VoiceHistoryComponent } from './modules/voice-response/voice-history/voice-history.component';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { ChatComponent } from './modules/chat/chat.component';
import { golfDigestAuthGuard } from './auth/guards/golf-digest-auth.guard';
import { OnCourseMapComponent } from './modules/golf-on-course/on-course-map/on-course-map.component';
import { InPlayComponent } from './modules/golf-on-course/in-play/in-play.component';

export const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate:[noAuthGuard]
      },
    ],
  },
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./modules/auth/auth.route').then((m) => m.AuthRoutes),
  //   canActivate: [noAuthGuard],
  // },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path:'home',
        canActivate: [authGuard],
        component: ChatComponent
      },
      {
        path: 'discover',
        loadChildren: () =>
          import('./modules/discover/discover.route').then((m) => m.ROUTES),
        canActivate: [authGuard],
      },
      {
        path: 'answer',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./modules/answer/answer.route').then((m) => m.AnswerRoutes),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/settings/settings.route').then(
            (m) => m.SettingsRoutes
          ),
        canActivate: [authGuard]
      },
      {
        path: 'voice-response/history',
        canActivate: [authGuard],
        component: VoiceHistoryComponent,
      },
      {
        path: 'map',
        loadChildren: () =>
          import('./modules/golf-on-course/on-course-map/on-course-map.route').then(
            (m) => m.OnCourseMapRoutes
          ),
        canActivate: [authGuard]
      },
      {
        path:'on-course',
        loadChildren: () =>
          import('./modules/golf-on-course/on-course/on-course.route').then((m) => m.OnCourseRoutes),
        canActivate: [authGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/public/pubic.route').then((m) => m.PublicRoutes),
      },
    ],
  },
  {
    path: 'voice-response',
    canActivate: [authGuard],
    component: VoiceResponseLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/voice-response/voice-response.route').then(
            (m) => m.VoiceRoutes
          ),
      },
    ],
  },
  // {
  //   path: 'on-course/:id',
  //   component: VoiceResponseLayoutComponent,
  //   children:[{
  //     path:'',
  //     component: InPlayComponent
  //   }],
  // },
  // golf digest layout
  {
    path: 'golf-digest',
    component: GolfDigestLayoutComponent,
    canActivate: [golfDigestAuthGuard],
    children: [
      {
        path: '',
        component: GolfDigestComponent,
      },
    ],
  },
  // Golf Digest login layout
  {
    path: '',
    component: GolfDigestLoginLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/auth/auth.route').then((m) => m.AuthRoutes),
        canActivate: [noAuthGuard],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];
