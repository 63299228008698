<div class="mainBG">
    <!-- dskMenuBg -->
    <div class="dskMenuBg d-mobile-none align-self-start" [ngClass]="{ 'collapsible': !expandMenu }" *ngIf="isSignedIn">
        <!-- dskMenu -->
        <div class="dskMenu">
            <div class="d-block">
                <!-- toggleMenu -->
                <div class="toggleMenu" (click)="expandMenu = !expandMenu">
                    <span></span>
                </div>
                <!-- toggleMenu -->
                <ul>
                    <li>
                        <a href="javascript:void(0)" routerLink="/home" routerLinkActive="active">
                            <label class="col hideTxt">{{'Home'| translate}}</label>
                            <span><img src="assets/images/menuIcon1.svg" alt=""></span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" routerLink="/on-course" routerLinkActive="active">
                            <label class="col hideTxt">{{'Play Golf' | translate}}</label>
                            <span><img src="assets/images/playGolf.svg" alt=""></span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" routerLink="/discover" routerLinkActive="active">
                            <label class="col hideTxt">{{'Discover'| translate}}</label>
                            <span><img src="assets/images/menuIcon2.svg" alt=""></span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="navigateToSettingPage('MyBag')"
                        [class.active]="activeTab === 'MyBag'">
                            <label class="col hideTxt">{{'MyBag' | translate}}</label>
                            <span><img src="assets/images/menuIcon4.svg" alt=""></span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="navigateToSettingPage('MyPartner')"
                        [class.active]="activeTab === 'MyPartner'">
                            <label class="col hideTxt">{{'My' | translate}} <b>{{'GolfAI' | translate}}</b></label>
                            <span><img src="assets/images/myGolf.svg" alt=""></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="btmBlck">
                <!-- <div class="txtArea hideTxt">
                    <h6>Complete your profile <br>+ better your game</h6>
                    <p>+ Choose your caddy<br>
                    + Upload your golf bag</p>
                </div> -->
                <div class="profileBg">
                    <span class="profile" (click)="navigateToSettingPage('MySettings')" [class.noImage]="!user?.image">
                        <img *ngIf="user?.image" [src]="user?.image" alt="">
                        <div *ngIf="!user?.image" class="profile-letter">{{user?.name?.substring(0, 2)?.toUpperCase()}}
                        </div>
                    </span>
                    <label (click)="navigateToSettingPage('MySettings')" class="col hideTxt">{{user?.name}}</label>
                </div>
            </div>
        </div>
        <!-- dskMenu -->
    </div>
    <!-- dskMenuBg -->

    <!-- mblSlideBg -->
    <div class="mblSlideBg" [ngClass]="{'active': isMobileMenuActive}" *ngIf="isSignedIn">
        <div class="mblSlideBg__header">
            <img src="assets/images/logoIcon.svg" alt="">
            <span class="closeIcon" (click)="isMobileMenuActive = !isMobileMenuActive">
                <img src="assets/images/closeWhite.svg" alt="">
            </span>
        </div>
        <!-- mblSlideBg__menu -->
        <div class="mblSlideBg__menu">
            <ul>
                <li>
                    <a href="javascript:void(0)" routerLink="/home">
                        <label>{{'Home'| translate}}</label>
                        <img src="assets/images/menuIcon1.svg" alt="">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" routerLink="/on-course" routerLinkActive="active">
                        <label>{{'Play Golf' | translate}}</label>
                        <img src="assets/images/playGolf.svg" alt="">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" routerLink="/discover">
                        <label>{{'Discover' | translate}}</label>
                        <img src="assets/images/menuIcon2.svg" alt="">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" (click)="navigateToSettingPage('MyBag')">
                        <label>{{'MyBag' | translate}}</label>
                        <img src="assets/images/menuIcon4.svg" alt="">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" (click)="navigateToSettingPage('MyPartner')">
                        <label>{{'My'| translate}} <b>{{'GolfAI' | translate}}</b></label>
                        <img src="assets/images/myGolf.svg" alt="">
                    </a>
                </li>
            </ul>
            <div class="txtArea">
                <h6>{{'EnhanceYourGame' | translate}} <br>
                    {{'With' | translate}} <span>{{'GolfAI' | translate}}</span></h6>
                <p>
                    + {{'ChooseYourPartner' | translate}}<br>
                    + {{'UploadGolfBag' | translate}}<br>
                    + {{'PartnerComingSoon' | translate}}<br>
                    + {{'BetterYourGame' | translate}}<br>
                </p>
            </div>
        </div>
        <!-- mblSlideBg__menu -->
        <div class="profileBg">
            <span class="profile" (click)="navigateToSettingPage('MySettings')" [class.noImage]="!user?.image">
                <img *ngIf="user?.image" [src]="user?.image" alt="">
                <div *ngIf="!user?.image" class="profile-letter">{{user?.name?.substring(0, 2)?.toUpperCase()}}
                </div>
            </span>
            <label (click)="navigateToSettingPage('MySettings')" class="col">My Settings</label>
        </div>
    </div>
    <!-- mblSlideBg -->

    <!-- mobileMenu -->
    <div class="mobileMenu bottom" *ngIf="isSignedIn && !hideExceptTopMenu">
        <ul>
            <li>
                <a href="javascript:void(0)" routerLink="/home" routerLinkActive="active">
                    <span><img src="assets/images/menuIcon1.svg" alt=""></span>
                    <label>{{'Home' | translate}}</label>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" routerLink="/on-course" routerLinkActive="active">
                    <span><img src="assets/images/playGolf.svg" alt=""></span>
                    <label>{{'Play Golf' | translate}}</label>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" routerLink="/discover" routerLinkActive="active">
                    <span><img src="assets/images/menuIcon2.svg" alt=""></span>
                    <label>{{'Discover' | translate}}</label>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="navigateToSettingPage('MyBag')"
                    [class.active]="activeTab === 'MyBag'">
                    <span><img src="assets/images/menuIcon4.svg" alt=""></span>
                    <label>{{'MyBag' | translate}}</label>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="navigateToSettingPage('MyPartner')"
                    [class.active]="activeTab === 'MyPartner'">
                    <span><img src="assets/images/myGolf.svg" alt=""></span>
                    <label>{{'My' | translate}} <b>{{'GolfAI' | translate}}</b></label>
                </a>
            </li>
        </ul>
    </div>
    <!-- mobileMenu -->

    <div class="container">
        <!-- topMenu -->
        <div class="topMenu" *ngIf="isSignedIn">
            <div class="logoBg">
                <a href="javascript:void(0)" routerLink="/home" class="d-none d-md-block">
                    <img src="assets/images/logoIcon.svg" alt="Golf AI" class="d-none d-md-block">
                    <img src="assets/images/mblLogo.svg" alt="Golf AI" class="d-md-none">
                </a>
                <span (click)="navigateToSettingPage('MySettings')" class="d-md-none">
                    <!-- {{user?.name?.substring(0, 2)?.toUpperCase()}} -->
                    <img *ngIf="user?.image" [src]="user?.image" alt="">
                    <div *ngIf="!user?.image">{{user?.name?.substring(0, 2)?.toUpperCase()}}</div>
                </span>
            </div>
            <div class="mainLogo">
                <img src="assets/images/logo.svg" alt="Golf AI" class="">
            </div>
            <div class="d-inline-flex">
                <a href="javascript:void(0)" class="mblIcon d-md-none d-inline-block"
                    (click)="isMobileMenuActive = !isMobileMenuActive">
                    <img src="assets/images/hamBugerIcon.svg" alt="">
                </a>
            </div>
        </div>
        <!-- topMenu -->
        <router-outlet></router-outlet>
        <!-- footer -->
        <div class="footer" *ngIf="!hideExceptTopMenu">
            <div class="d-flex left">
                <img src="assets/images/logoBlack.svg" alt="">
                <ul>
                    <li>
                        <a target="_blank" href="http://linkedin.com/company/golf-ai">
                            <img src="assets/images/linkedin-logo.svg" alt=""></a>
                    </li>
                    <!-- <li>
                        <a target="_blank" href="https://www.instagram.com/golfai_app/"><img
                                src="assets/images/instagramIcon.svg" alt=""></a>
                    </li> -->
                </ul>
            </div>
            <div class="right d-flex flex-column">
                <ul class="order-md-2">
                    <li>
                        <!-- <a routerLink="/privacy-policy">Privacy Policy</a> -->
                        <a href="/assets/files/Golf.ai-Privacy-Policy.pdf" target="_blank">{{'PrivacyPolicy' |
                            translate}}</a>
                    </li>
                    <li>
                        <!-- <a routerLink="/terms-of-service">Terms & Conditions</a> -->
                        <a href="/assets/files/Golf.ai-Terms-of-Service.pdf" target="_blank">{{'TermsAndCondition' |
                            translate}}</a>
                    </li>
                    <li>
                        <a href="mailto:contact@golf.ai">{{'support@golf.ai'}}</a>
                    </li>
                </ul>
                <p>{{'CopyrightText' | translate}}</p>
            </div>
        </div>
        <!-- footer -->
    </div>
</div>