// token.interceptor.ts
import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { TokenService } from './token.service';
import { catchError, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';

export const tokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
) => {
  const tokenService = inject(TokenService);

   // Skip the interceptor for 'assets/' requests
   if (req.url.includes('assets/')) {
    return next(req);
  }
  // Check if the request should bypass the interceptor
  if (req.headers.has('skip-interceptor')) {
    const clonedRequest = req.clone({
      headers: req.headers.delete('skip-interceptor'), // Remove custom flag
    });
    return next(clonedRequest).pipe(
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
  let token = tokenService.getToken;
  let clonedRequest = req;
  if (
    !req.url.includes('/refresh-token') &&
    !req.url.includes('https://s3.ap-southeast-2.amazonaws.com') && 
    !req.url.includes('https://storage.googleapis.com')
  ) {
    if (token && tokenService.isTokenExpired(token)) {
      // Token is expired, attempt to refresh
      return tokenService.refreshToken().pipe(
        switchMap((response: any) => {
          token = response.AccessToken;
          clonedRequest = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next(clonedRequest);
        }),
        catchError((error: any) => {
          tokenService.removeTokens();
          return throwError(() => new Error(error));
        })
      );
    }

    clonedRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    clonedRequest = req.clone({});
  }

  return next(clonedRequest).pipe(
    catchError((error: any) => {
      // Handle token expiration during request
      if (error && error.status === 401 && token) {
        tokenService.removeTokens();
      }
      return throwError(() => error);
    })
  );
};
