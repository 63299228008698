import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SharedService } from '../shared/services/shared.service';
import { User } from '../model/user.model';
import { TranslatePipe } from '../pipes/translate.pipe';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    RouterOutlet, RouterModule, CommonModule, TranslatePipe],
  providers: [],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit, OnDestroy {
  expandMenu = true;
  private _subscription!: Subscription;
  user: User | null = null;
  prevUrl: string = '';
  currentUrl: string = '';
  isMobileMenuActive = false;
  isSignedIn = false;
  activeTab = '';
  hideExceptTopMenu=false
  constructor(
    private _sharedService: SharedService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    router.events.subscribe((val) => {
      if (isPlatformBrowser(this.platformId) && document) {
        const body = document.getElementsByTagName('body')[0];
        if (val instanceof NavigationEnd) {
          const navigation = this.router.getCurrentNavigation();
          this.hideExceptTopMenu = navigation?.extras.state?.['hideExceptTopMenu'] || false;

          this.expandMenu = false;
          this.mobileSlideMenu = false;
          this.isMobileMenuActive = false;
          if(!val.url.includes('/settings')){
            this.activeTab = '';
          } else if (val.url.includes('?tab=MyBag')){
            this.activeTab = 'MyBag';
          } else if (val.url.includes('?tab=MyPartner')){
            this.activeTab = 'MyPartner';
          }
          
        }
      }
    });
  }

  ngOnInit() {
    this._sharedService.user$.subscribe(user => {
      this.user = user;
      if(this.user.email){
        this.isSignedIn = true;
      }
    });
    this._sharedService.isMyBagActive$.subscribe(res => {
      if(res){
        this.activeTab = res;
      }
    })
  }
  navigateToSettingPage(tabName: string){
    // this._sharedService.setByBagSettingTab(tabName);
    // this.router.navigateByUrl("/settings");
    this.router.navigate(["settings"], {
      queryParams: { tab: tabName }, // Set the new query parameter
      queryParamsHandling: 'merge', // Merge with existing query params
    });
    this.activeTab = tabName;
    this.expandMenu = false;
    this.mobileSlideMenu = false;
    this.isMobileMenuActive = false;
  }
  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  mobileSlideMenu = false;
}
